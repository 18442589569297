<template>
    <LayoutNext>
        <template #page-title>
            User's Adjustments
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/users">Users</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    User's Adjustments
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <MDBCard id="Adjustments" tag="form" class="shadow card-container">
            <MDBCardHeader class="py-3">
                <h6 class="mb-0">
                    Adjustments for
                    <strong class="text-capitalize">
                        {{ sendParamsDetails.userDetail.firstName }}
                        {{ sendParamsDetails.userDetail.lastName }}
                    </strong>
                </h6>
            </MDBCardHeader>
            <MDBCardBody class="d-flex flex-column py-0 pb-3 position-relative">
                <DeductionsComponent />
            </MDBCardBody>
        </MDBCard>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBBreadcrumb, MDBBreadcrumbItem, MDBCardHeader } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import DeductionsComponent from "@/components/Deductions/DeductionsComponent.vue";
import { useDeductionsStore } from "@/store/deductionsStore";
import { ref, defineProps, watch } from "vue";
import { useSendParamsDetails } from "@/store/sendParamsDetails";
import { useUserDetailStore } from "@/store/userDetailStore";
import { storeToRefs } from "pinia";
import { useTitle } from "@vueuse/core";
import { useRouter } from "vue-router";

useTitle("User's Adjustments | Creator Shield");

const props = defineProps(["firstName", "lastName"]);

const deductionsStore = useDeductionsStore();
const sendParamsDetails = useSendParamsDetails();
deductionsStore.userName = {
    firstName: ref(props.firstName),
    lastName: ref(props.lastName),
};

const userDetailStore = useUserDetailStore();
const { isUser } = storeToRefs(userDetailStore);
const router = useRouter();
watch(isUser, (newVal) => {
    if (newVal) {
        router.push("/dashboard");
    }
});
</script>

<style scoped>
#Adjustments {
    color: var(--accent);
}

.btn-primary {
    background-color: var(--primary);
}

.btn-secondary {
    background-color: var(--accent);
}

.card,
.card-body {
    overflow-y: auto;
}

.card-container {
    flex: 1 1 auto;
    overflow: auto;
}
</style>
